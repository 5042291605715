import React, { useState, useEffect } from "react";
import Profile from "./profile/Profile";
import Title from "./components/Title";
import Card from "./components/Card";
import { Link } from "react-router-dom";
import HomeRow from "./components/HomeRow";
import CardCatching from "./components/CardCatching";

const Home = () => {
    const style = {
        padding: "0",
        margin: "0",
    };

    const [curationdata, setCurationdata] = useState([]);

    const setCurationdataWrapper = async () => {
        const cdata = await fetch(`https://blog1.zangzini.com/home`);
        const cdataJson = await cdata.json()
        setCurationdata(cdataJson);
    }

    useEffect(() => {
        setCurationdataWrapper()
    }, [])

    const cardCatchingText1 = <div><div style={{textAlign:"center", fontSize:"x-large"}}>Coming soon</div><div style={{textAlign:"center", fontSize:"xxx-large"}}>Choicemaid</div></div>
    const cardCatchingText2 = <div><div style={{textAlign:"center", fontSize:"x-large"}}>Coming soon</div><div style={{textAlign:"center", fontSize:"xxx-large"}}>BrainHurricane</div></div>
    const cardCatchingText3 = <div><div style={{textAlign:"center", fontSize:"x-large"}}>Coming soon</div><div style={{textAlign:"center", fontSize:"xxx-large"}}>a.k.a. MBTI</div></div>
    const cardCatchingText4 = <div style={{textAlign:"center", fontSize:"x-large"}}>Coming soon</div>
    const cardCatchingText5 = <div style={{textAlign:"center", fontSize:"x-large"}}>Coming soon</div>

    return (
        <>
            <div>
                <Profile></Profile>
            </div>
            <div>
                <table style={{height:"20vh", width:"100%", padding:"0", marginTop:"15px", border: "none", borderSpacing: "0px", borderCollapse: "collapse"}}>
                    <thead></thead>
                    <tbody>
                        <tr>
                            <td style={{paddingRight:"5px", paddingBottom:"5px"}}><CardCatching text={cardCatchingText1} backgroundUrl="https://cdn.pixabay.com/photo/2015/12/09/17/11/vegetables-1085063_1280.jpg"></CardCatching></td>
                            <td style={{ paddingBottom:"5px"}}><CardCatching text={cardCatchingText2} backgroundUrl="https://images.unsplash.com/photo-1598520106830-8c45c2035460?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2274&q=80"></CardCatching></td>
                        </tr>
                        <tr>
                            <td rowspan="2" style={{paddingRight:"5px", width: "40%",}}><CardCatching text={cardCatchingText3} backgroundUrl="https://images.freeimages.com/images/large-previews/462/cherry-blossoms-japan-3-1552814.jpg"></CardCatching></td>
                            <td style={{ paddingBottom:"5px" }}><CardCatching text={cardCatchingText4} backgroundUrl=""></CardCatching></td>
                        </tr>
                        <tr>
                            <td style={{padding:0}}><CardCatching text={cardCatchingText5} backgroundUrl=""></CardCatching></td>
                        </tr>
                    </tbody>
                </table>
                
                
                
            </div>
            <div>
                { curationdata.map((item, idx) => {
                    if (item.type === "postId") {
                        return <HomeRow type="postId" data={curationdata[idx]}></HomeRow>
                    } else if (item.type === "title-content") {
                        return <HomeRow type="title-content" data={curationdata[idx]}></HomeRow>
                    }
                })}
            </div>
        </>
    );
};

export default Home;