import React from "react";
import styles from "./Profile.module.css";

const Profile = () => {
    return (
        <div className={styles.Profile}>
            <h1>Designed by zangzini in Gyeonggi-do</h1>
            <div className={styles.ProfileDesc}>
                <span className={styles.ProfileDescTitle}>MBTI</span>
                <br></br>
                ESTP
            </div>
            <div className={styles.ProfileDesc}>
            <span className={styles.ProfileDescTitle}>Interested in</span>
                <br></br>
                Morphology, Design, Programming
            </div>
            <div className={styles.ProfileDesc}>
            <span className={styles.ProfileDescTitle}>Loves and enjoys</span>
                <br></br>
                Working out, Games, Traveling
            </div>
            <br></br>
            <a className={styles.profileA} href="https://blog.naver.com/zangzini" target="_blank">네이버 블로그</a>와 <a className={styles.profileA} href="https://zangzini.tistory.com" target="_blank">티스토리</a>에서도 저를 만나볼 수 있어요.
            <br></br>
            <a className={styles.profileA} href="https://supplier.tistory.com" target="_blank">저품질 광고 블로그</a>도 운영하고 있답니다.

        </div>
    );
};

export default Profile;