import React from 'react';
import Home from './pages/contents/home/Home';
import About from './pages/About';
import Boards from './pages/Boards';
import Post from './pages/Post';
//import Header from './pages/header/Header';
import Board from './pages/contents/boards/Board';
import { Route, Routes, Outlet, BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/board" element={<Outlet />} >
            <Route path="" element={<Boards />} />
            <Route path=":boardName" element={<Board />} />
            <Route path=":boardName/:postId" element={<Post />} />
          </Route>
      </Routes>
    </>
  );
}

export default App;
