import React, { useEffect, useState } from 'react';
import styles from './Card.module.css';
import CardTag from './CardTag';

const Card = (props) => {
    
    let bgColor = "white", boxShadow = "none";
    if ("bgColor" in props) {
        bgColor = props["bgColor"];
        // boxShadow = "5px 5px 1px 3px rgba(151, 151, 169, 0.089)";
        boxShadow = "none";
    }

    // onMouseEnter, onMouseLeave -> no events bubble (no propagation)
    // cf. onMouseOver, onMouseOut
    const handleMouseEnter = (e) => {
        e.currentTarget.style.color = "white";
        e.currentTarget.style.backgroundColor = "#0f5241";
    }

    const handleMouseLeave = (e) => {
        e.currentTarget.style.color = "black";
        e.currentTarget.style.backgroundColor = bgColor;
    }

    if (props.postTag) {
    return (
        <div name="handlable" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{backgroundColor: bgColor, boxShadow: boxShadow}} className={styles.Card}>
            <span style={{fontFamily: "Pretendard Bold"}}>{props.postTitle}</span>
            <div style={{height:"0px"}}></div>
            <span style={{fontFamily: "Pretendard Medium"}}>{props.postDesc}</span>
            <div style={{height:"10px"}}></div>
            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                {props.postTag?.split(",").map((item, idx) => <CardTag key={idx} text={item}></CardTag>)}
            </div>
        </div>
    )
    } else {
        return (
            <div name="handlable" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{backgroundColor: bgColor, boxShadow: boxShadow}} className={styles.Card}>
                <span style={{fontFamily: "Pretendard Bold"}}>{props.postTitle}</span>
                <div style={{height:"0px"}}></div>
                <span style={{fontFamily: "Pretendard Medium"}}>{props.postDesc}</span>
            </div>
        )
    }
};

export default Card;