import { logDOM } from "@testing-library/react";
import React, { useEffect, useState } from "react";
import { useParams, useLocation } from 'react-router-dom';
import MarkdownView from "react-showdown";
import { stampToPrettyString } from "../common/DateParser";

const Post = () => {
    const { boardName, postId } = useParams();
    const [metadata, setMetadata] = useState("");
    const [ contentAlign, setContentAlign ] = useState("left");
    const { pathname } = useLocation();

    const setMetadataWrapper = async () => {
        const postData = await fetch(`https://blog1.zangzini.com/post/${postId}`);
        setMetadata(await postData.json());
    }

    useEffect(() => {
        setMetadataWrapper();
    }, []);

    useEffect(() => {
        if (boardName === "tech") {
            setContentAlign("left");
        } else {
            setContentAlign("center");
        }
    }, [pathname]);

    useEffect(() => {}, [contentAlign]);

    return (
        <div style={{fontFamily: "Pretendard Medium", textAlign: "left"}}>
            <div style={{fontFamily: "Pretendard Bold", fontSize: "xxx-large", marginBottom: "10px"}} name="title">{metadata.title_kor}</div>

            <table style={{borderStyle: "none", padding: "0", margin: "0"}}>
                <tr>
                    <td style={{fontFamily: "Pretendard Bold", color: "grey"}}>Post</td>
                    <td style={{fontFamily: "Pretendard Bold", color: "black"}}>#{metadata.id}</td>
                </tr>
                <tr>
                    <td style={{fontFamily: "Pretendard Bold", color: "grey"}}>Written</td>
                    <td style={{fontFamily: "Pretendard Bold", color: "black"}}>{stampToPrettyString(metadata.created)}</td>
                </tr>
                <tr>
                    <td style={{fontFamily: "Pretendard Bold", color: "grey"}}>Edited</td>
                    <td style={{fontFamily: "Pretendard Bold", color: "black"}}>{stampToPrettyString(metadata.edited)}</td>
                </tr>
                <tr>
                    <td style={{fontFamily: "Pretendard Bold", color: "grey"}}>Tags</td>
                    <td style={{fontFamily: "Pretendard Bold", color: "black"}}>{metadata.tag}</td>
                </tr>
            </table>
            
            <MarkdownView
              markdown={metadata.content}
              options={{ tables: true, emoji: true }}
              style={{textAlign: contentAlign, fontSize: "large"}}
            />
        </div>
    )
};

export default Post;