import React, { useEffect, useState } from 'react';
import styles from './CardCatching.module.css';
import CardTag from './CardTag';

const CardCatching = (props) => {

    // onMouseEnter, onMouseLeave -> no events bubble (no propagation)
    // cf. onMouseOver, onMouseOut

    // const handleMouseEnter = (e) => {
    //     e.currentTarget.style.color = "white";
    //     e.currentTarget.style.backgroundColor = "#0f5241";
    // }

    // const handleMouseLeave = (e) => {
    //     e.currentTarget.style.color = "black";
    //     e.currentTarget.style.backgroundColor = "white";
    // }

    if (props.backgroundUrl !== "") {
    return (
        // <div name="handlable" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={styles.CardCatching}>
        <div name="handlable" className={styles.CardCatching} style={{ backgroundImage: `url(${props.backgroundUrl})` }}>
            <div className={styles.CardCatchingContent}>
                {props.text}
            </div>
        </div>
    );
    } else {
        return (
            // <div name="handlable" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={styles.CardCatching}>
            <div name="handlable" className={styles.CardCatching} style={{background:"radial-gradient(circle at 7.5% 24%, rgb(237, 161, 193) 0%, rgb(250, 178, 172) 25.5%, rgb(190, 228, 210) 62.3%, rgb(215, 248, 247) 93.8%)"}}>
                <div className={styles.CardCatchingContent}>
                    {props.text}
                </div>
            </div>
        );   
    }
};

export default CardCatching;