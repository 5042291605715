import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";

const Boards = () => {
    const [boards, setBoards] = useState([]);

    const setBoardData = async () => {
        const boardData = await fetch(`https://blog1.zangzini.com/board`);
        const aaa = await boardData.json()
        console.log(aaa);
        setBoards(aaa);
    }

    useEffect(() => {
        setBoardData();
    }, []);




    const getBoardsList = async(url) => {
        console.log(`url:${url}`);
        const resp = (await axios.get(url)).data;
        console.log(resp);
        setBoards(resp);
    };

    // console.log("whatwhatwhat");

    return (
        <div>
            <Link to="/about">
                <img src="/crying.jpg" alt="crying-child" style={ {width: "300px"} } />
            </Link>
            <ul>
                {boards.map(item => (
                    <li key={item.date}>
                        title: {item.title}
                    </li>
                ))}
                {JSON.stringify(boards)}
            </ul>
        </div>
    )
};

export default Boards;