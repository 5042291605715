function stampToPrettyString(stamp) {
    if (!stamp || stamp == undefined) {
        return "Date is not defined";
    }

    const year = stamp.substring(0, 4);
    const month = stamp.substring(4, 6);
    const day = stamp.substring(6, 8);
    const hour = stamp.substring(8, 10);
    const min = stamp.substring(8, 10);
    const sec = stamp.substring(10, 12);
    const mils = stamp.substring(12, 15);
    return `${year}. ${month}. ${day} ${hour}:${min}`;
}

export { stampToPrettyString };