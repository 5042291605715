import React, { useEffect, useState } from "react";
import { Link, Outlet, Router } from "react-router-dom";
import Card from "./Card";

const HomeRowTileWithTitleAndContent = (props) => {
    return (
        <Link to={ props.tiles.uri }>
            {/* <Card bgColor="rgb(236, 236, 236)" postTitle={ props.tiles.title } postDesc={ props.tiles.description }></Card> */}
            <Card bgColor="transparent" postTitle={ props.tiles.title } postDesc={ props.tiles.description }></Card>
        </Link>
    )
};

export default HomeRowTileWithTitleAndContent;