import React, { useEffect, useState } from "react";
import { Link, Outlet, Router } from "react-router-dom";
import Card from "./Card";
import CardTag from "./CardTag";

const HomeRowTileWithPostIds = (props) => {
    const postId = props.postId

    const [postMetadata, setPostMetadata] = useState(undefined);
    const [boardMetadata, setBoardMetadata] = useState(undefined);
    const [linkURI, setLinkURI] = useState("");


    const getPostInfo = async (postId) => {
        const res = await fetch(`https://blog1.zangzini.com/post/${postId}`);
        const resJson = await res.json()
        setPostMetadata(resJson)

        getBoardNameWithBoardId(resJson.board_id)
    }

    const getBoardNameWithBoardId = async (boardId) => {
        const res = await fetch(`https://blog1.zangzini.com/board/${boardId}`);
        const resJson = await res.json();
        setBoardMetadata(resJson)
    }

    const createAndSetLinkURI = () => {
        const uri = `/board/${boardMetadata?.name_eng}/${postId}`;
        setLinkURI(uri);
    }

    useEffect(() => {
        getPostInfo(postId);
    }, [])

    useEffect(() => {
        createAndSetLinkURI()
    }, [boardMetadata])

    return (
        // <Link to={linkURI}>
        //     <div style={{ marginBottom: "10px" }}>
        //         <div>
        //             <span style={{ fontFamily: "Pretendard Bold" }}>{postMetadata?.title_kor}</span>
        //             <span style={{ fontFamily: "Pretendard Medium", color: "grey" }}> {boardMetadata?.name_kor}</span>
        //         </div>
        //         <div name="summary" style={{ fontFamily: "Pretendard Medium" }}>{postMetadata?.summary}</div>
        //     </div>
        // </Link>
        <Link to={linkURI}>
            {/* <Card bgColor="rgb(236, 236, 236)" postTitle={postMetadata?.title_kor} postDesc={postMetadata?.summary} postTag={postMetadata?.tag}></Card> */}
            <Card bgColor="transparent" postTitle={postMetadata?.title_kor} postDesc={postMetadata?.summary} postTag={postMetadata?.tag}></Card>
        </Link>
    );

    // 글 제목이나 요약 필요함
    // boardName이랑 postId로 주소만들어줘야함
};

export default HomeRowTileWithPostIds;