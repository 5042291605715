import React, { useEffect, useState } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import Card from '../home/components/Card';

const Boards = () => {
    const [posts, setBoards] = useState([]);

    let { boardName } = useParams()

    const { pathname } = useLocation();

    const setBoardData = async () => {
        const boardData = await fetch(`https://blog1.zangzini.com/post?boardName=${boardName}`);
        const boardDataJson = await boardData.json()
        console.log(boardDataJson);
        setBoards(boardDataJson);
    }

    useEffect(() => {
        setBoardData();
    }, [pathname]);

    if (posts.length > 0) {
        return (
            <div style={{ "fontFamily": "Pretendard Bold" }}>
                총 {posts.length}개의 글이 있어용
                <br></br>
                {posts.map(item => <Link to={`/board/${boardName}/${item.id}`}>
                    <Card key={item.id} postTitle={item.title_kor} postDesc={item.summary}></Card>
                </Link>)}
            </div>
        )
    } else {
        return (
            <div style={{ "fontFamily": "Pretendard Bold" }}>
                어머 글이 없어요!!!
            </div>
        )
    }
};

export default Boards;