import React from "react";
import HomeRowTileWithPostIds from "./HomeRowTileWithPostIds";
import HomeRowTileWithTitleAndContent from "./HomeRowTileWithTitleAndContent";
import styles from "./HomeRow.module.css";

const HomeRow = (props) => {
    const tileType = props.type
    const rowData = props.data

    if (tileType === "postId") {
        return <>
            <div><h2 className={styles.homeRowTitle}>{rowData.title}</h2></div>
            <div style={{display: "grid", gridTemplateColumns:"minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)", rowGap:"15px", columnGap:"15px"}}>
                {rowData.postIds.map(item => <HomeRowTileWithPostIds type="postIds" postId={item}></HomeRowTileWithPostIds>)}
            </div>
        </>
    } else if (tileType === "title-content") {
        return <>
            <div><h2 className={styles.homeRowTitle}>{rowData.title}</h2></div>
            <div style={{display: "grid", gridTemplateColumns:"minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)", rowGap:"15px", columnGap:"15px"}}>
                {rowData.tiles.map(item => <HomeRowTileWithTitleAndContent type="title-content" tiles={item}></HomeRowTileWithTitleAndContent>)}
            </div>
        </>
    }
};

export default HomeRow;