import React from "react";
import styles from "./ScrollingTextDiv.module.css";
import { useState, useEffect, useRef } from "react";

const ScrollingTextDiv = (props) => {

    const [ data, setData ] = useState(styles.Inner);
    const rootRef = useRef(null);

    useEffect(() => {
        if (rootRef.current.offsetWidth > rootRef.current.parentElement.offsetWidth) {
            console.log(rootRef.current);
            setData([styles.Inner, styles.scrolled].join(' '));
        }
    }, []);

    return (
        <div className={styles.Outer} >
            <div className={data} ref={rootRef}>
                {props.text}
            </div>
        </div>
    );
};

export default ScrollingTextDiv;