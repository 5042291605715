import React from "react";
import ScrollingTextDiv from "./ScrollingTextDiv";

const CardTag = (props) => {
    const divStyle = {
        fontFamily: "Pretendard Medium",
        fontSize: "small",
        backgroundColor: "#2abd89",
        borderRadius: "3px",
        //color: "rgb(150, 0, 150)",
        color: "white",
        height: "1.5em",
        padding: "0 0.25em 0 0.25em",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "0 0.25em 0.25em 0",
        maxWidth: "5vw",
    }

    return (
        <div style={divStyle}>
            <ScrollingTextDiv text={props.text}></ScrollingTextDiv>
        </div>
    );
};

export default CardTag;